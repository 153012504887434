/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-03-14 12:09:46
 * @LastEditTime: 2020-10-30 10:32:41
 * @LastEditors: Please set LastEditors
 */

/**
 * axios封装
 */
import axios from "axios";
import config from "@/config";
import router from "@/router";
import { Spin, Message } from "view-ui-plus";
import qs from "qs"; // 数据序列化插件
// 设置基础路径
const baseUrl =
  process.env.NODE_ENV === "development"
    ? config.baseUrl.dev
    : config.baseUrl.pro;

// 实例化axios实例
var Axios = axios.create({
  baseURL: baseUrl,
  timeout: 600000, // 超时时间
  withCredentials: true, // 跨域请求是否带上凭证
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "X-URL-PATH": location.pathname
  }
});
Axios.defaults.headers.get["Content-Type"] =
  "application/json;charset=utf-8";
Axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=utf-8";
// 请求拦截器
Axios.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.error(error);
  }
);

// 响应拦截
Axios.interceptors.response.use(
  // 响应成功
  res => {
    Spin.hide();
    let nowHref = window.location.href;
    let isMobileWeb = nowHref.indexOf("/mobile") != -1;

    // if (res.data.code === 1 && res.data.message === 'success') {
    if (res.data.code == "0") {
      // 成功获取到数据

      return res;
    } else if (res.data.code === "1") {
      // 重新登录
      // Message.warning({
      //   content: '请重新登录！2s后将跳转至登录页',
      //   duration: 2
      // })

      // debugger;
      // router.matched.length;
      // router.matched[0].name;
      // router.name.indexOf("mobile");
      // debugger;

      if (isMobileWeb) {
        router.replace({
          name: "mLogin"
        });
      } else {
        router.replace({
          name: "log_timeout"
        });
      }
      // if (
      //   (router.matched.length > 0 && router.matched[0].name == "mobile") ||
      //   router.name.indexOf("mobile") > 0
      // ) {
      //   router.replace({
      //     name: "mLogin"
      //   });
      // } else {
      //   router.replace({
      //     name: "log_timeout"
      //   });
      // }

      return;
    } else if (res.data.code === "-1") {
      // 其他错误
      let nowHref = window.location.href;
      let isMobileWeb = nowHref.indexOf("/mobile") != -1;
      if (isMobileWeb) {
        return Promise.reject(res.data.msg || "请求出错了");
      } else {
        let txt = res.data.msg || "请求出错了";
        Message.warning({
          content: txt,
          duration: 2.5
        });
        // return Promise.reject(res.data.msg || "请求出错了");
      }
      return res
    } else if (res.data.code === "-2") {
      // 其他错误 无法通用的错误状态，需要自定义
      Frozen(res.data.data)
     
      return res
    }else {
      // 其他错误
      // let txt = res.data.msg || "请求出错了";
      // Message.warning({
      //   content: txt,
      //   duration: 2.5
      // });
      // return Promise.reject(res.data.msg || "请求出错了");

      if (isMobileWeb) {
        return Promise.reject(res.data.msg || "请求出错了");
      } else {
        let txt = res.data.msg || "请求出错了";
        Message.error({
          content: txt,
          duration: 2.5
        });
        // return Promise.reject(res.data.msg || "请求出错了");
        return res
      }
    }
  },
  // 响应失败
  error => {
    // debugger
    Spin.hide();
    let nowHref = window.location.href;
    let isMobileWeb = nowHref.indexOf("/mobile") != -1;
    if (error.code === "ECONNABORTED") {
      if (!isMobileWeb) {
        Message.error({
          content: "服务器错误，请稍后重试！",
          duration: 2.5
        });
      }
    } else {
      if (!isMobileWeb) {
        Message.error({
          content: "服务器错误，请稍后重试！",
          duration: 2.5
        });
      }
    }

    return Promise.reject(error);
  }
);

export const http = (method, url, params, loading) => {
  loading = loading || "";
  if (loading) {
    Spin.show();
  }
  if (method.toLowerCase() === "post") {
    return Axios.post(url, qs.stringify(params));
  } else {
    return Axios.get(url, {
      params: params
    });
  }
};
export const httpJson = (method, url, params) => {
  if (method.toLowerCase() === "post") {
    return Axios.post(url, params);
  } else {
    return Axios.get(url, {
      params: params
    });
  }
};
