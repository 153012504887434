<template>
  <div class="demo">
    <div class="demo-image-lazy-vertical">
      <template v-for="url in verticalUrlList" :key="url">
        <Image :src="url" width="100%" lazy>
          <template #error>
            <Icon type="ios-image-outline" size="24" color="#ccc" />
          </template>
        </Image>
      </template>
    </div>
  </div>
</template>

<script>
import { http, httpJson } from "@/libs/axios";
export default {
  name: "Demo",
  setup() {},
  data() {
    return {
      verticalUrlList: [
        // 'https://file.iviewui.com/images/image-demo-1.jpg',
        // 'https://file.iviewui.com/images/image-demo-2.jpg',
        // 'https://file.iviewui.com/images/image-demo-3.jpg',
        // 'https://file.iviewui.com/images/image-demo-4.jpg',
        // 'https://file.iviewui.com/images/image-demo-5.jpg',
        // 'https://file.iviewui.com/images/image-demo-6.jpg',
        // 'https://file.iviewui.com/images/image-demo-7.jpg',
        // 'https://file.iviewui.com/images/image-demo-8.jpg',
        // 'https://file.iviewui.com/images/image-demo-9.jpg',
        // 'https://file.iviewui.com/images/image-demo-10.jpg'
      ],
    };
  },
  methods: {
    handleClick(id) {
      
      http("get", "/h5/" + id, {})
        .then((res) => {
          if (res.data.code == "0") {
            let data = res.data.data.list;
            let signature=res.data.data.signature.replace(/【/g, "").replace(/】/g, "");;
            this.verticalUrlList = data.map((r) => r.longUrl);
           debugger
               document.title=signature;
       
          } else {
          }
        })
        .catch((error) => {});
    },
  },
  mounted() {
    //  console.log(this.$route.params.id
    //  )
    if (this.$route.params.id) {
      let id = this.$route.params.id;
      if (id != "" && id != null) {
        this.handleClick(id);
      }
    }
  },
};
</script>

<style lang="less" scoped>
.demo {
  width: 100%;
}
</style>
