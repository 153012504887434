/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-05-24 17:26:14
 * @LastEditTime: 2019-10-23 10:53:36
 * @LastEditors: Please set LastEditors
 */
export default {
    /**
     * @description token在Cookie中存储的天数，默认1天
     */
    cookieExpires: 1,
    /**
     * @description api请求基础路径
     */
    baseUrl: {
       dev: '/qiandi-menber/',
      // pro: 'http://user.test.qd6666.com.cn/qiandi-menber/'
       pro: 'http://rongqiyouxin.com/qiandi-menber/'
    }
};